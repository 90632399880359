
img{
  pointer-events: none
}

.no-copy-text{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}


.foot {
  background: black;
  padding: 10px 20px;
  font-size: 24px;
  transition: 0.8s;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  overflow: hidden;
  filter: blur(0px);
  border-top: 1px solid white;
}
.foot1 {
  color: #a1a1a1;
}
.foot1:hover {
  color: #000;
}
.footerBottom a {
  color: #a1a1a1;
  transition: 0.8s;
}
.foot1:hover .footerBottom a {
  color: #000;
  transition: 0.8s;
}
.foot::before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #f9a526;
  z-index: -1;
  transition: 0.8s;
}
.foot1::before {
  top: 0;
  border-radius: 0 0 50% 50%;
}
.foot1:hover::before {
  height: 200%;
}

footer {
  color: #fff;
  text-align: center;
}

.footerContainer {
  width: 100%;
}

.containerst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
}
.boxs {
  position: relative;
  overflow: hidden;
}
.boxs img {
  transition: all linear 0.8s;
 

}
img.hover-imgs {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.boxs:hover img {
  opacity: 0;
}
.foot:hover img.hover-imgs {
  opacity: 1;
}

.footnavs {
  background: none;
  padding: 0 150px;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
   justify-content: space-evenly; 
}

.footerBottom {
  text-align: center;
}
@media (max-width: 1506px){
  .footnavs {
    background: none;
    padding: 0 150px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-evenly;
    text-align: left;
}
}

@media screen and (max-width: 1426px){
 #navbar.active {
    right: 0px;
    top: 103px;
}

}
@media (max-width: 1024px) {
  .boxs img {
    
    padding: 20px;
  
  }
  .footnavs {
    background: none;
    padding: 0 0;
    justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  /* padding-top: 10px; */
   justify-content: space-evenly; 
  }
  .place-foot{
 
    width: 140px;
    text-align: left;
    /* align-items: center; */
  }
  .manali2 {
    display: none;
  }
}

.kulu {
  position: relative;
  text-align: center;
  align-items: center;
  top: 0;
  left: 0;
}

.manali1 {
  position: relative;
  top: 0;
  left: 0;
}

.manali2 {
  position: absolute;
  top: 62px;
  left: 200px;
  bottom: 200px;
}
.manali3 {
  position: relative;
  top: -10px;
  left: 650px;
  bottom: 200px;
}


@media (max-width: 700px) {
  .boxs img {
    
    padding: 20px;
  
  }
  .foot {
    background: black;
    padding: 10px 5px;
  }
  .footnavs {
    background: none;
    padding: 0 0;
    justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  /* padding-top: 10px; */
   justify-content: space-evenly; 
  }
  .place-foot{
 
    width: 130px;
    text-align: left;
    /* align-items: center; */
  }
}

img {
  pointer-events: none;
}
/* p{
  margin: 0;
} */
.no-copy-text {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}
@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
@font-face {
  font-family: RalewayBold;
  src: url(../Ralewayfont/Raleway-Bold.ttf);
}
@font-face {
  font-family: RubikMedium;
  src: url(../Rubik/Rubik-Medium.ttf);
}
.product-banner {
  width: 100%;
  height:568.9px ;
  object-fit: cover;
}

.catgory-row {
  /* justify-content: center;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
   justify-content: space-evenly; 
  gap: 5px;
  padding: 0px 20px ; */

  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0px 20px;
}

.productpera {
  color: #aaa;
  text-align: center;

  font-family: RalewayMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 100px;
}

.tabs {
  display: inline-flex;

  align-items: flex-start;
  gap: 28px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.list-product {
  padding: 0 10px;
}

.producticecream {
  margin: 10px 0;
  width: 150px;

  border-radius: 45px;
  border: 1px solid #4b4b4b;
  grid-row: 1;

  color: #fff;
  font-family: RalewayMedium;
  font-size: 14px;

  font-weight: 500;
}
.product-heading-ice {
  font-family: RalewayBold;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.product-under {
  border-radius: 10px;
  border: 1px solid #413118;
  height: 460px;
  background: #111;
  cursor: pointer;
}
.product-border-img{
  width: 250px;
}
.product-3-divs {
  margin: 16px 16px;
  border-radius: 20px;
  border: 1px solid black;
  background: black;
}
.product-heading {
  color: #ffad31;

  font-family: RalewayBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  padding: 0 20px;
}
.product-pera {
  color: #878787;

  font-family: RalewayMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  padding: 0 20px;
}
/* .but-try-it {
  text-align: left;
}
.but-try-it button {
  color: #ffad31;
  background: none;
  font-family: RalewayBold;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  border: none;
  padding: 0 20px;
} */

/* mobile responsive */
@media (min-width: 768px) and (max-width: 1024px) {
  .productpera {
    color: #aaa;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 15px;
  }
  .catgory-row {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0px 20px;
  }
  /* .catgory-row {
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px, 1fr);
    overflow-x: scroll;
    gap: 5px;
  }
  .catgory-row::-webkit-scrollbar {
    display: none;
  } */
}
@media (min-width: 481px) and (max-width: 767px) {
  .productpera {
    color: #aaa;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 15px;
  }
  .catgory-row {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0px 20px;
  }
  /* .catgory-row {
    justify-content: space-between;
    
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px, 1fr);
    overflow-x: scroll;
    gap: 5px;
  }
  .catgory-row::-webkit-scrollbar {
    display: none;
  } */
}
@media (min-width: 320px) and (max-width: 480px) {
  .productpera {
    color: #aaa;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 15px;
  }
  .catgory-row {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0px 20px;
  }
  /* .catgory-row{
    justify-content: space-between;

    
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px, 1fr);
    overflow-x: scroll;
    gap: 5px;
  }
  .catgory-row::-webkit-scrollbar {
    display: none;
  } */
}
@media screen and (max-width: 1400px) {
  .product-banner{
    width: 100%;
    height: 491.23px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1200px) {
  .product-banner{
    width: 100%;
    height: 413.55px;
    object-fit: cover;
  }
}
@media screen and (max-width: 992px) {
  
  .product-banner{
    width: 100%;
    height: 309.99px;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .product-banner{
    width: 100%;
    height: 232.31px;
    object-fit: cover;
  }
}

@media screen and (max-width: 600px) {
  .product-border-img{
    width: 300px;
  }
  .product-under {
    height: 510px;
  }
  .product-banner {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

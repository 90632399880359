@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}
@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
@font-face {
  font-family: RalewayBold;
  src: url(../Ralewayfont/Raleway-Bold.ttf);
}
@font-face {
  font-family: RubikMedium;
  src: url(../Rubik/Rubik-Medium.ttf);
}
@font-face {
  font-family: RubikLight;
  src: url(../Rubik/Rubik-Light.ttf);
}
@font-face {
  font-family: GothamMedium;
  src: url(../Gotham-Rounded-Font/Gotham\ Rounded\ Medium.otf);
}

.allproductborder {
  border-radius: 20px;
  border: 1px solid #2d2d2d;
  
  background: #121212;
}
.productborder {
  border-radius: 20px;
  /* background: black; */
  /* border: 1px solid #2d2d2d; */
}

.allproduct-heading {
  font-family: RalewayBold;
  font-size: 50px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: linear-gradient(to right, #414141, #f9a526);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: left;
  position: relative;
  top: -1.5%;
  

}
.title-box{
  min-height: 377px;
  position: relative;
  top: -1%;
}
@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}
.product-varient {
  display: flex;
  flex-wrap: wrap;
}
.allproduct-pera {
  color: #bbb;
  text-align: justify;
  font-family: RalewayMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pera-box{
  height: 15rem;

}
.avilable {
  color: #fff;
  
  font-family: GothamMedium;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}
.available-box{
  position: relative;
  top:0%
}
.captionmodel {
  color: #b3b3b3;
  text-align: left;
  font-family: RubikLight;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 12px;
}
.under-product {
  border-radius: 20px;
  background: black;
  border: 1px solid #2d2d2d;
}

.overlay {
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */
  /* top: 0; */
  /* bottom: 0; */
  margin: 10px 0;
  min-height:60px ;
  background: rgba(0, 0, 0, 0);
  /* overflow: hidden; */
  /* width: 100%;
  height: 100%; */
  /* transition: 0.5s ease; */
}

/* .item {
  position: relative;
  overflow: hidden;
} */
/* .title-overlay {
  height: auto;
  
  top: 0;
  opacity: 0;
} */
/* .item:hover .title-overlay {
  opacity: 1;
  height: auto;
  padding: 40px 0;
  
} */
.text {
  font-family: RubikMedium;
  color: #ffad31;
  /* position: absolute; */
  /* top: 80%;
  left: 50%; */
  /* -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  text-align: center;
  
}
/* .mobile-name{
  display: none;
} */

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .available-box{
    position: relative;
    top:10px
  }
  .allproduct-heading{
    position: relative;
  top: 2%;
  margin-top: 10px;
  }
  .title-box{
    height: unset;
  }
}

@media screen and (max-width: 1399px) and (min-width: 995px)   {
  .img-1280{
    height: 900px;
    object-fit: cover;
  }
  .available-box{
    position: relative;
    top:11%;
    left: 0.9%;
  }
  .allproduct-heading{
    font-size:35px ;
   
  }
  
}

@media screen and (max-width: 900px) {
  .allproduct-heading {
    font-size: 35px;
    text-align: center;
    position: relative;
  top: 1%;
  
  }
  .title-box{
    min-height: unset;
    top: 2%;
  }
  .allproduct-pera {
    font-size: 18px;
    /* text-align: justify; */
    text-align: center;
  }
  .text {
    font-size: 16px;
  }
  .product-varient {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .available-box{
    position: relative;
    top:1%;
    left: 0.9%;
  }

  
  /* .avilable{
    font-size: 30px;
    
  } */

  @media screen and (max-width:399px) {
    .available-box{
      position: relative;
      margin-top: 60px;

      top:1%;
      left: 0.9%;
      
    }
    
    /* .pera-box p {
      height: 25rem;
      font-size: 18px;
    } */
    /* .avilable{
      font-size: 24px;
    } */
  }
  /* .text {
    display: none;
  } */
  /* .mobile-name{
    font-family: RubikMedium;
    color: #ffad31;
    display: contents;
    font-size: 20px;
   
  
  } */
}
@media screen and (max-width: 290px) {
  .allproduct-heading{
    font-size: 25px;
  }
  .title-box{
    min-height: unset;
  }
}

@media screen and (max-width: 992px) {
  .pera-box{
    height: unset;
  
  }
}

img{
  pointer-events: none
}

.no-copy-text{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}




@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}


nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: black;
  padding: 5px;
  width: 100%;
  height: 119px;
}
.desi-logo {
  width: 150px;
}
.under {
  color: white;
}
.naves .under:hover {
  color: #f9a526;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul {
  margin-top: revert-layer;
  padding-right: 0;
}

#navbar li {
  list-style: none;
  padding: 5px 18px;
  position: relative;
}
#navbar li a {
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-decoration: none;
  justify-content: center;
}

#mobile {
  display: none;
}
#mobile i {
  color: black;
  align-items: center;
}

.nav-item {
  padding: 10px;
}

.social-icon ul {
  list-style: none;
  display: flex;
}

#navbar .do {
  padding-top: 25px;
}
#navbar .stme {
  color: #f9a526;
}
/* #navbar .instalinkt {
  color: #f9a526;
}
#navbar .fblinkt {
  color: #f9a526;
} */
@media screen and (max-width: 1426px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 114.5px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: black;

    padding: 0px 0 0 0px;
    transition: 0.3s ease-in-out;
  }

  #navbar .do {
    padding-top: 0px;
  }
  #navbar.active {
    right: 0px;
  }
  #navbar li {
    margin-bottom: 25px;
  }
  #mobile {
    display: block;
  }
  #mobile i {
    font-size: 24px;
    cursor: pointer;
    color: #fff;
  }
}
.stme {
  border-radius: 10px;
  background: #191919;
  padding: 10px 10px 10px 10px;
  /* border: none; */
  color: white;
}
.stme:hover {
  border: 1px solid #f9a526;
  transition: 0.3s ease-in-out;
  
}
.instalinkt{
  border-radius: 50%;
  background: #191919;
  padding: 10px 11px  ;
  color: #f9a526;
  
}

.fblinkt{
  border-radius: 60%;
  background: #191919;
  padding: 10px 14px  ;
  color: #f9a526;
}


.nav2 li a span {
  display: block;
  transition: all 0.15s ease-in;
}
.hidden-span {
  opacity: 0;
}

.nav2 li a:hover span {
  transform: translateY(-25px);
  -webkit-transform: translateY(-25px);
  opacity: 0;
}
.nav2 li a:hover .hidden-span {
  transform: translateY(-18px);
  -webkit-transform: translateY(-17px);

  opacity: 1;
}

@media screen and (max-width: 600px) {
  
 
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 93.5px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: black;

    padding: 0px 0 0 0px;
    transition: 0.3s ease-in-out;
  }
  .desi-logo {
    width: 120px;
  }
}




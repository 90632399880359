img {
  pointer-events: none;
}

.no-copy-text {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}
@font-face {
  font-family: RalewayBold;
  src: url(../Ralewayfont/Raleway-Bold.ttf);
}
@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
@font-face {
  font-family: RubikBold;
  src: url(../Rubik/Rubik-Bold.ttf);
}

.state-file{
  display: flex;
}
.contact-banner {
  width: 100%;
  height: 568.9px;
  object-fit: cover;
}
.contact-main-border {
  border-radius: 20px;
  border: 1px solid #282828;
  filter: blur(0px);
  background: #131313;
}
.contactusheading {
  font-family: RubikBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  background-image: linear-gradient(to right, #414141, #f9a526);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
}
@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}
.contactus {
  color: #fff;

  font-family: RalewayBold;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contactpera {
  color: #8c8c8c;

  font-family: RalewayMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.labels {
  color: #fff;

  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.inputborder {
  font-family: Raleway;
  border-radius: 6px;
  border: 1px solid #2f2f2f;
  width: 100%;
  margin: 5px 0px 20px 0px;
  padding: 10px;
  background: #131313;
  caret-color: white;
  color: white;
}

.gmapframe {
  padding: 40px 10px;
}
#contact {
  padding: 30px 10px 30px 10px;
}

.dividesection {
  padding: 0 2px;
}
.contact-border {
  border-radius: 20px;
  border: 1px solid #282828;
  background: rgba(19, 19, 19, 0.4);
}

.contact-melbourne {
  color: #dbdbdb;
  text-align: center;
  font-family: RalewayBold;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact-sub-text-left {
  text-align: center;
}
.contact-text-left {
  text-align: center;
}
.contact-melbourne-left-align {
  justify-content: center;
}
.contact-melbourne-mail {
  color: #9b9b9b;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  word-break: break-all;
}
.desi-kothi-1300 {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.fa-location-dot {
  border: 1px solid #3a3a3a;
  border-radius: 47px;
  background: #191919;
  padding: 8px 10px;
  color: #ffad31;
}
.contact-box {
  padding: 58px 20px;

  align-items: center;

  border-radius: 20px;
  border: 1px solid #282828;
  background: rgba(19, 19, 19, 0.4);
}
.sydney {
  color: #dbdbdb;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.fa-envelope {
  border: 1px solid #3a3a3a;
  border-radius: 50px;
  background: #191919;
  padding: 10px 10px;
  color: #ffad31;
}
.fa-phone {
  border: 1px solid #3a3a3a;
  border-radius: 47px;
  background: #191919;
  padding: 9px 9px;
  color: #ffad31;
}
.find-us {
  text-align: center;
  font-family: RubikBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: linear-gradient(to right, #414141, #f9a526);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
}

.find-us-border {
  border-radius: 20px;
  border: 1px solid #282828;

  background: #080808;

  padding: 50px 50px;

  align-items: center;
}
.find-us-melbourne {
  color: #f9a526;
  text-align: center;
  font-family: RalewayBold;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.find-us-outlet {
  color: #dbdbdb;
  font-family: RalewayMedium;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.under-border {
  padding: 20px;
  
  align-items: center;
  gap: 23px;
  border-radius: 10px;
  border: 1px solid #333;
  background: #151515;
}
.contact-clayton {
  color: #fff;
  font-family: RalewayBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.contact-clayton span {
  color: #f9a526;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.last-box {
  padding: 70px 480px;
  text-align: center;
  align-items: center;
  background: #080808;
  border-radius: 20px;
  border: 1px solid #282828;
}
.last-box-sydney {
  color: #f9a526;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.last-box-outlet {
  color: #dbdbdb;
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.last-box-under {
  border-radius: 10px;
  border: 1px solid #323232;
  background: #151515;

  padding: 20px;

  align-items: center;
}
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f9a526;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.mapsg {
  width: 100%;
  height: 528px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* mobile responsive */
@media screen and (max-width: 1400px) {
  .contact-box {
    padding: 20px;

    align-items: center;

    border-radius: 20px;
    border: 1px solid #282828;
    background: rgba(19, 19, 19, 0.4);
  }
  .mapsg {
    width: 100%;
    height: 553px;
  }
  .contact-banner {
    width: 100%;
    height: 491.23px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1200px) {
  .contact-banner {
    width: 100%;
    height: 413.55px;
    object-fit: cover;
  }
}
@media screen and (max-width: 992px) {
  .gmapframe {
    padding: 0px 0px 35px 0px;
  }
  .contact-banner {
    width: 100%;
    height: 309.99px;
    object-fit: cover;
  }
  .state-file{
    display: block;
  }
  .image-file{
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .contactusheading {
    font-size: 60px;
  }
  .contact-banner {
    width: 100%;
    height: 232.31px;
    object-fit: cover;
  }
}
@media screen and (max-width: 600px) {
  .contact-banner {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .contact-melbourne-left-align {
    justify-content: left;
    padding-left: 10px;
  }
  .contact-sub-text-left {
    text-align: left;
  }
  .contact-text-left {
    text-align: left;
    padding-left: 10px;
  }
  .find-us {
    font-size: 40px;
  }
  .find-us-border {
    padding: 15px 10px 0px 10px;
    /* padding: 70px 10px; */
  }
  .contactusheading {
    font-size: 40px;
  }
  .find-us-melbourne {
    font-size: 30px;
  }
  .find-us-outlet {
    font-size: 16px;
  }
  .contact-box {
    padding: 10px;

    align-items: center;

    border-radius: 20px;
    border: 1px solid #282828;
    background: rgba(19, 19, 19, 0.4);
  }
  .last-box {
    padding: 12px 10px 22px 10px;
  }
  .contactus {
    font-size: 35px;
  }
  .contactpera {
    font-size: 18px;
  }
  .contact-melbourne {
    font-size: 35px;
  }
  .sydney {
    font-size: 35px;
  }
}

.main-width {
  width: 100%;
  /* border: 1px solid black; */
}

.under1320width {
  max-width: 1320px;
  min-width: 250px;
  margin: 0 auto;
}
.all-page-padding {
  padding: 0 10px;
}
.news-event-heading-pera .news-event-heading {
  font-family: RalewayBold;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-align: justify;
  color: #ffad31;
}
.news-event-heading-pera .news-event-heading-date {
  font-family: RalewayBold;
  font-weight: 700;
  font-size: 16px;
  line-height: 18.78px;
  text-align: justify;
  color: #ffad31;
}
.news-event-heading-pera .news-event-pera {
  font-family: Raleway;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  color:#878787
}
.news-event-page-img-1 {
  max-width: 1320px;
  min-width: 250px;
  /* height: 517px; */
  width: 100%;
  margin: 30px 0;
}
.news-event-page-img-1 img {
  max-width: 1320px;
  min-width: 250px;
  /* height: 462px; */
  width: 100%;
  border-radius: 15px;
  object-fit: contain;
}

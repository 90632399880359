/* SelectDropdown.css */

.custom-select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
  }
  

  
  .custom-select {
    /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #555;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
    outline: none;
    position: relative;
  }
  
  .custom-select:hover {
    background-color: #444;
  }
  
  .custom-select:active,
  .custom-select:focus {
    border-color: #00bcd4;
  }
  
  /* Styling the dropdown arrow */
  .custom-select::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #fff;
    transition: transform 0.3s ease-in-out ;
  }

  .custom-select:focus:before {
    transform: translateY(-50%) rotateX(180deg);
  }
  
  /* Styling options */
  .custom-select option {
    background-color: #333 !important;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  .custom-select option:hover{
    background-color: #be3535 ;
    color: red;
  }
  
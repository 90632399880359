
.no-copy-text{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
.main-seco{
  padding: 10px 0;
}




marquee{
padding: 10px 0;  
position: absolute;
display: block;
height: 570px;
top: 0;
}



@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}
@font-face {
  font-family: RalewayBold;
  src: url(../Ralewayfont/Raleway-Bold.ttf);
}
@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
@font-face {
  font-family: RalewayItalic;
  src: url(../Ralewayfont/Raleway-Italic.ttf);
}
@font-face {
  font-family: Rubik;
  src: url(../Rubik/Rubik-Black.ttf);
}

.scroll {
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  visibility: inherit;
}

.scroll-text {
  height: 100%;
  text-align: center;
  -moz-transform: translateY(50%);
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -moz-animation: my-animation 5s linear infinite;
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 5s linear infinite;
  
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-200%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-200%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}



.font-wax {
  font-family: Rubik;
  font-size: 90px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 3px;
  color: #fba627;
  -webkit-text-stroke: 1px #fcc133;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  visibility: inherit;
  position: relative;
 
}

.banner1 {
  position: relative;
  text-align: center;
  align-items: center;
  top: 0;
  left: 0px;
}

.backetbanner {
  
  width: 100%;
  
  position: relative;
  
  text-align: center;
  align-items: center;
  justify-content: center;
 
}

.creambanner {
  width: 65%;
  position: absolute;
  top: -250px;
  left: 200px;
  bottom: 200px;
  text-align: center;
  align-items: center;
  opacity: 1;
  
}

.effect{
  opacity:0.01;
  /* top: 400px; */
  transition: opacity 5s ease-in-out ;
}
.sideeffect{
  opacity:1;
  /* top: -250px; */
  transition: opacity 5s ease-in-out;
}
/* .effect-back{
  opacity:0.01;
  /* bottom: 600px; */
  /* transition: bottom 10s ease-in;
} */
.sideeffect-back{
  /* opacity:1; */
  /* bottom: 0px;
  transition: bottom 5s ease-in-out; */
} 





img.vert-move {
  -webkit-animation: mover 3s infinite alternate;
  animation: mover 3s infinite alternate;

}
img.vert-move {
  -webkit-animation: mover 3s infinite alternate;
  animation: mover 3s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-20px);
  }
}
img.down-move {
  -webkit-animation: mover 3s infinite alternate;
  animation: mover 3s infinite alternate;
}
img.down-move{
  -webkit-animation: dhruv 3s infinite alternate;
  /* animation: mover 3s infinite alternate; */
  animation-name: dhruv 3s infinite alternate;
}
@-webkit-keyframes dhruv {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes dhruv {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(10px);
  }
}

.top-seller {
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
}

@keyframes rainbow-animation{
  to {
   
    background-position: 4500vh;
  }
}
.rotatez img {
  width: 200px;
  transition: 1s ease;
  margin-bottom: 350px;
}

.rotatez img:hover {
  -webkit-transform: rotateZ(-30deg);
  -ms-transform: rotateZ(-30deg);
  transform: rotateZ(-30deg);
  transition: 1s ease;
}
.top-div{
 justify-content: space-between;
 display: flex;
 text-align: center;
 gap: 10px;
}
.main-divs {
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* margin: 0 20px 0 20px; */
}
.main-divs:hover {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  animation-name: example;
  animation-duration: 0.25s;
  border-right: 3px solid #f9a526;
  border-bottom: 3px solid #f9a526;
}

.under-box {
  border-radius: 20px;
  background: #161616;
  border-bottom: 0.01px solid #f9a526;
  width:300px ;
  height:500px ;
}
.brighten img {
  -webkit-filter: brightness(50%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.immm {
  /* background: black; */

  width: 210px;
  border-radius: 50%;
}
.top-under-all-heading {
  color: #818181;
  height: 60px;
  text-align: center;
  font-family: RalewayBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
}
.top-under-all-pera {
  color: #4f4f4f;
  
  text-align: center;
  font-family: RalewayMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
}

.read-more {
  color: #89590f;
  background: none;

  font-family: RalewayItalic;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.rotatey img {
  width: 200px;
  transition: 1s ease;
  margin-top: 350px;
}

.rotatey img:hover {
  -webkit-transform: rotateZ(30deg);
  -ms-transform: rotateZ(30deg);
  transform: rotateZ(30deg);
  transition: 1s ease;
}

.main-divs:hover .brighten img {
  -webkit-filter: brightness(100%);
}

.main-divs:hover .top-under-all-heading {
  color: #fff;
  transition: 0.8s;
}
.main-divs:hover .top-under-all-pera {
  color: #818181;
  transition: 0.8s;
}
.main-divs:hover .read-more {
  color: #f9a526;
  transition: 0.8s;
}

.aboutborder {
  border-radius: 20px;
  border: 1px solid #272727;

  background: rgba(9, 9, 9, 0.1);

  backdrop-filter: blur(8px);
  padding: 20px 10px;
}

.aboutus {
  text-align: center;
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
  
  
}

.aboutpera {
  color: #626262;
  /* text-align: justify; */
  font-family: RalewayMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
}
.btn {
  border-radius: 10px;
  border: 1px solid rgba(251, 166, 39, 0.3);
  background: none;
  padding: 10px 20px;
  font-size: 16px;
  transition: 0.8s;
  font-family: Raleway;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: relative;
  overflow: hidden;
  filter: blur(0);
}
.btn1 {
  color: #fba627;
}
.btn1:hover {
  color: #000;
}
.btn::before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #f9a526;
  z-index: -1;
  transition: 0.8s;
}
.btn1::before {
  bottom: 0;
  border-radius: 50% 50% 0 0;
}
.btn1:hover::before {
  height: 180%;
}
.cupcream {
  padding-top: 300px;
  width: 150px;
}
.classytrad {
  text-align: center;
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
}


.classyborder {
  border-radius: 20px;
  border: 1px solid #272727;
  background: rgba(9, 9, 9, 0);
  padding: 20px 10px;
  filter: blur(0px);
}
.leaf1 {
  width: 258px;
  margin-left: -112px;
}
.Philosophyabout {
  text-align: center;
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
}


.leaf2 {
  width: 240px;
  margin-left: 60px;
}
.from-our-menu {
  text-align: center;
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
}
.from-under {
  border-radius: 10px;
  border: 1px solid #413118;
   height: 450px;
   padding: 16px 0;
  background: #111;
}
.homes-froms-unders{
  margin: 0 16px ;
  border-radius: 20px;
  border: 1px solid black;
  background: black;
}
.libox {
  width: 250px;
}
.but-try-it {
  /* text-align: left; */
  margin-bottom: 0;

}
.but-try-it button {
  color: #ffad31;
  background: none;
  font-family: RalewayBold;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  border: none;
  /* padding-top: 1px; */
  /* padding: 0 20px; */
}
.from-heading {
  color: #ffad31;
  margin: 16px;
  font-family: RalewayBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  height: 50px;
  /* min-height: 60px; */
}
.from-pera {
  color: #878787;
  font-family: RalewayMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  /* padding: 0 20px; */
  margin: 16px;
  height: 50px;
  
  
  
}

.fromms {
  margin-top: 200px;
}

.testimonials {
  text-align: center;
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
}

.testimonial .row {
  margin-top: 90px;
}

.profile {
  padding: 0px 0px 20px;
  border: 1px solid white;
  /* background: #161616; */
  border-radius: 10px;
  height: 350px;
  /* margin: 0 30px; */
}
.profile .user {
  top: -60px;
  position: relative;
  left: calc(50% - 60px);
  border: 10px solid #414141;
}
.user {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.no-image{
  left: calc(50% - 50%);

}
.profile h3 {
  font-size: 22px;
  margin-top: -40px;
  color: #f9a526;
  font-family: RalewayBold;
  font-weight: 700;
}
.co-spans {
  font-size: 14px;
  color: #878787;
  font-family: RalewayMedium;
}
blockquote {
  font-family: RalewayMedium;
  font-size: 16px;
  line-height: 30px;
  color: #878787;
  text-align: center;

  padding: 0 20px;
}


/* mobile responsive media query  */

@media screen and (max-width: 1440px) {
  blockquote {
    font-family: RalewayMedium;
    font-size: 16px;
    line-height: 30px;
    color: #878787;
    text-align: center;
  
    padding: 0 15px;
  }
  .font-wax {
    font-size: 90px;
  }

  .creambanner {
    position: absolute;
    top: -240px;
    /* left: 340px; */
    bottom: 200px;
    text-align: center;
    align-items: center;
  }

}
@media screen and (max-width: 1400px) {
  
  marquee {
    padding: 10px 0;
    position: absolute;
    display: block;
    height: 500px;
}
  blockquote {
    font-family: RalewayMedium;
    font-size: 16px;
    line-height: 22px;
    color: #878787;
    text-align: center;
  
    padding: 0 10px;
  }
  
  .from-heading{
    margin: 8px;
    font-size: 17px;

  }

  .from-pera{
   margin: 8px;
  }
  .homes-froms-unders{
    margin: 5px;
    border-radius: 20px;
    border: 1px solid black;
    background: black;
  }
  .font-wax {
    font-size: 70px;
  }

  .creambanner {
    position: absolute;
    top: -204px;
    /* left: 301px; */
    bottom: 200px;

    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .from-under {
    border-radius: 10px;
    border: 1px solid #413118;
     height: 390px;
     padding: 16px 0;
    background: #111;
  }
  .libox{
    width: 200px;
  }
  .homes-froms-unders{
    margin: 0px;
    border-radius: 0;
    border: 1px solid black;
    background: black;
  }
  
  .top-div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 0fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(300px, 0fr);
    overflow-x: scroll;
    
  }
  .top-div::-webkit-scrollbar {
    display: none;
  }



  .font-wax {
    font-size: 65px;
  }

  .creambanner {
    position: absolute;
    top: -165px;
    left: 166px;
    bottom: 200px;
    text-align: center;
    align-items: center;
  }
}
@media screen and (max-width: 1024px) {
  .profile{
    border:none;
  }
}
@media screen and (max-width: 992px) {
  blockquote {
    font-family: RalewayMedium;
    font-size: 16px;
    line-height: 22px;
    color: #878787;
    text-align: center;
  
    padding: 0 10px;
  }
  .from-heading{
    margin: 20px;
    height: unset;
  }
  .from-pera{
    margin: 20px;
    height: unset;
  }
  .from-under {
    border-radius: 10px;
    border: 1px solid #413118;
    height: unset;
    width: 326px;
    padding: 16px 0;
    background: #111;
    margin: 0 auto;
}
  /* .from-under{
    height: unset;
  } */
  .homes-froms-unders{
    margin: 0 16px;
    border-radius: unset;
    border: 1px solid black;
    background: black;
  }
  .fromms {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .libox {
    width: 300px;
  }
  .font-wax {
    font-size: 48px;
  }

  .creambanner {
    position: absolute;
    top: -118px;
    left: 126px;
    bottom: 200px;
    text-align: center;
    align-items: center;
  }
  .rotatey img {
    display: none;
  }
  .rotatez img {
    display: none;
  }
  /* .mscreen {
    padding-bottom: 20px;
  } */
  /* .top-div{
    padding-left: 0px;
    padding-right: 0px;
  } */
  .cupcream {
    display: none;
  }
  .leaf1 {
    display: none;
  }
  .leaf2 {
    display: none;
  }
}
@media screen and (max-width: 767.5px) {
  
  .creambanner {
    position: absolute;
    top: -80px;
    left: 85px;
    bottom: 200px;
    text-align: center;
    align-items: center;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 0px;
  }
}
@media screen and (max-width: 599px) {
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
   /* left: 60px; */
    top: 140px;
  }
}



@media screen and (max-width: 575px) {
  .font-wax {
    font-size: 22px;
  }

  .creambanner {
    top: -125px;
    left: 85px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 70px;
  }
}
@media screen and (max-width: 571px) {
  .creambanner {
    top: -125px;
    left: 95px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 70px;
  }
}
@media screen and (max-width: 556px) {
  .creambanner {
    top: -126px;
    left: 100px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 70px;
  }
}
@media screen and (max-width: 545px) {
  .creambanner {
    top: -124px;
    left: 92px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 70px;
  }
}
@media screen and (max-width: 538px) {
  .creambanner {
    top: -124px;
    left: 94px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 70px;
  }
}
@media screen and (max-width: 524px) {
  .creambanner {
    top: -118px;
    left: 96px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 75px;
  }
}
@media screen and (max-width: 514px) {
  .creambanner {
    top: -120px;
    left: 93px;
    bottom: 200px;
  }
  
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 98px;
  }
}
@media screen and (max-width: 494px) {
  .creambanner {
    top: -141px;
    left: 80px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 100px;
  }
}
@media screen and (max-width: 480px) {
  .creambanner {
    top: -138px;
    left: 78px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 110px;
  }
}
@media screen and (max-width: 473px) {
  .creambanner {
    top: -135px;
    left: 70px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 110px;
  }
}
@media screen and (max-width: 455px) {
  .creambanner {
    top: -130px;
    left: 68px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 110px;
  }
}
@media screen and (max-width: 436px) {
  .creambanner {
    top: -127px;
    left: 65px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 110px;
  }
}
@media screen and (max-width: 423px) {
  .creambanner {
    top: -125px;
    left: 70px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 110px;
  }
}
@media screen and (max-width: 412px) {
  .creambanner {
    top: -120px;
    left: 62px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 110px;
  }
}
@media screen and (max-width: 400px) {
  .creambanner {
    top: -120px;
    left: 62px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 130px;
  }
}
@media screen and (max-width: 393px) {
  .creambanner {
    top: -117px;
    left: 60px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 130px;
  }
}
@media screen and (max-width: 381px) {
  .creambanner {
    top: -112px;
    left: 59px;

    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 130px;
  }
}
@media screen and (max-width: 375px) {
  .creambanner {
    top: -112px;
    left: 55px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 140px;
  }
}
@media screen and (max-width: 281px) {
  .font-wax {
    font-size: 15px;
  }

  .creambanner {
    top: -22px;
    left: 45px;
    bottom: 200px;
  }
  .banner1 {
    position: relative;
    text-align: center;
    align-items: center;
    top: 170px;
  }
  
}


@media screen and (max-width: 600px) {
  .backetbanner {
  
    width: 150%;
    
    text-align: center;
    align-items: center;
    justify-content: center;
    top: -80px;
    bottom: 80px;
    position: relative;
    left: -100px;
    right: 100px;
 
   
  }
  .creambanner {
    width: 70%;
    position: absolute;
    bottom: -200px;
    top: -200px;
   
    left: -90px;
    right: 90px;
    text-align: center;
    align-items: center;
   
    
   
  }
  .from-under {
    width: unset;
  height: unset;
  
  }
  .homes-froms-unders{
    margin: 0 16px;
    border-radius: 20px;
    border: 1px solid black;
    background: black;
  }
  /* .backetbanner {
    width: 450px;

    left: -12px;
    position: relative;
    text-align: left;
    align-items: left;
    justify-content: left;
  } */
  .font-wax{
    font-size: 30px;
  }
  
  marquee{
    padding: 10px 0;  
    position: absolute;
    display: block;
    height: 310px;
    }
    .main-seco{
      padding: 0;
      height: 300px;

    }
  .top-div {
    
    gap: 50px;
  }
  .profile{
    border:none;
  }
  /* .backetbanner {
    width: 500px;
    right: 0;
    left: 0;
    position: relative;
    inset: 0;
    text-align: left;
    align-items: left;
    justify-content: left;
  } */
.libox {
    width: 300px;
  }
  .immm {
    /* background: black; */
  
    width:300px;
    border-radius: 50%;
  }

  .under-box {
    
    width:330px ;
    height:550px ;
  }
  .scroll {
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    visibility: inherit;
    height: 100%;
  }

  .scroll-text {
    height: 100%;
    text-align: center;

    -moz-transform: translateY(50%);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    -moz-animation: my-animation 5s linear infinite;
    -webkit-animation: my-animation 5s linear infinite;
    animation: my-animation 5s linear infinite;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateY(100%);
    }
    to {
      -moz-transform: translateY(-200%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateY(100%);
    }
    to {
      -webkit-transform: translateY(-200%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateY(-30%);
      -webkit-transform: translateY(-30%);
      transform: translateY(-30%);
    }
    to {
      -moz-transform: translateY(-200%);
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  }
  .top-seller {
    font-size: 35px;
  }
  .rotatez img {
    width: 150px;
    transition: 1s ease;
    margin-bottom: 30px;
    padding-left: -10px;
  }
  /* .mscreen {
    padding-bottom: 20px;
  } */
 
  
  /* .top-div{
    padding-left: 0px;
    padding-right: 0px;
  } */
  .aboutus {
    font-size: 35px;
    padding-top: 10px;
  }
  .aboutpera {
   
    font-size: 18px;
    padding: 0 15px;
  }
  .cupcream {
    display: none;
  }
  .classytrad {
    font-size: 35px;
    padding-top: 10px;
  }
  .mbut {
    padding-top: 20px;
  }
  .Philosophyabout {
    font-size: 35px;
    padding-top: 10px;
  }
  .leaf1 {
    display: none;
  }
  .leaf2 {
    display: none;
  }
  .from-our-menu {
    font-size: 40px;
    padding-bottom: 40px;
  }
  .fromms {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .last2 {
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .testimonials {
    font-size: 35px;

    font-weight: 700;
  }
}
.slick-center .testimonial{
  height: 350px;
}
.slick-center .profile{
  border:none;
}
.slick-center {
  background-color: #f9a526;
  margin-top: 90px;
  border-radius: 10px;
}
.slick-center .testiname{
  color: black;
}
.slick-center .co-spans{
  color: #000;
}
.slick-center blockquote{
  color: #000;
}
.slick-center .user{
  border: 10px solid black;
}
.slick-center .profile .user{
  top: -90px ;
}

.slick-center .profile h3 {
  
  margin-top: -70px;
  
}

.slick-center .testimonial .row{
  
  margin-top: 30px;
}
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f9a526;
  color: #000;
  font-size: 20px;
  border: 5px solid white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button.visible {
  opacity: 1;
}

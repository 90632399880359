
.no-copy-text{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}


@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}
@font-face {
  font-family: RalewayBold;
  src: url(../Ralewayfont/Raleway-Bold.ttf);
}
@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
@font-face {
  font-family: RalewayItalic;
  src: url(../Ralewayfont/Raleway-Italic.ttf);
}
@font-face {
  font-family: Rubik;
  src: url(../Rubik/Rubik-Black.ttf);
}
.about-banner{
  width: 100%;
  height: 568.9px;
  object-fit: cover;
}
.aboutheading {
  color: #f9a526;
  font-family: RalewayBold;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  text-align: center;
  margin: 0;
}
.aboutperagraph {
  color: #5c5c5c;
  text-align: center;
  font-family: RalewayMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 150px;
  margin: 0;
}
.borders {
  border-radius: 20px;
  border: 1px solid #2c2c2c;
  background: rgba(13, 13, 13, 0.5);
  backdrop-filter: blur(9px);
  padding: 50px;
}
.aboutheading1 {
  font-family: RalewayBold;

  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  background-image: 
  linear-gradient(to right, #414141,#f9a526); 
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 80s linear infinite;
  margin: 0;
}
@keyframes rainbow-animation{
  to {
   
    background-position: 4500vh;
  }
}
.aboutperagraph1 {
  color: #5c5c5c;
  text-align: center;
  font-family: RalewayMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.nowater {
  border-radius: 17px;
  border: 1px solid #898989;

  text-align: center;
  background: linear-gradient(
    336deg,
    #151515 8.54%,
    rgba(21, 21, 21, 0) 95.11%
  );

  display: flex;
  padding: 39px 28px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.noadded {
  color: #9a9a9a;

  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nowater:hover img.grayscale {
  opacity: 1;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  filter: none;
}

img.grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: gray;
  -webkit-transition: all 0.6s ease;
  opacity: 0.5;
}
.noeggno {
  padding-bottom: 40px;
}

/* mobile responsive */

@media (min-width: 768px) and (max-width: 1024px) {
  .borders {
    padding: 10px;
  }
  .aboutheading {
    font-size: 50px;
  }

  .aboutperagraph {
    font-size: 20px;
    padding: 0 0px;
  }
  .aboutheading1 {
    font-size: 50px;
  }
  /* .nowater:hover img.grayscale {
    opacity: none;
    filter: none !important;
    -webkit-filter: none !important;
    filter: none;
  }
  
  img.grayscale {
    filter: none !important;
    -webkit-filter: none !important;
    filter: none !important;;
    -webkit-transition: none;
    opacity: none;
  } */
}
@media (min-width: 481px) and (max-width: 767px) {
  .borders {
    padding: 10px;
  }
  .aboutheading {
    font-size: 35px;
  }

  .aboutperagraph {
    font-size: 18px;
    padding: 0 15px;
    
  }

  .aboutheading1 {
    font-size: 35px;
  }
  .aboutperagraph1 {
    font-size: 18px;
    padding: 0 15px;
    
  }
  .nowater:hover img.grayscale {
    opacity: none;
    filter: none !important;
    -webkit-filter: none !important;
    filter: none;
  }
  
  img.grayscale {
    filter: none !important;
    -webkit-filter: none !important;
    filter: none !important;;
    -webkit-transition: none;
    opacity: inherit;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .borders {
    padding: 10px;
  }
  .aboutheading {
    font-size: 35px;
  }

  .aboutperagraph {
    font-size: 18px;
    padding: 0 15px;
    
  }

  .aboutheading1 {
    font-size: 35px;
  }
  .aboutperagraph1 {
    font-size: 18px;
    padding: 0 15px;

    
  }
  .nowater:hover img.grayscale {
    opacity: none;
    filter: none !important;
    -webkit-filter: none !important;
    filter: none;
  }
  
  img.grayscale {
    filter: none !important;
    -webkit-filter: none !important;
    filter: none !important;;
    -webkit-transition: none;
    opacity: inherit;
  }
  
}
@media screen and (max-width: 1400px) {
  .about-banner{
    width: 100%;
    height: 491.23px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1200px) {
  .about-banner{
    width: 100%;
    height: 413.55px;
    object-fit: cover;
  }
}
@media screen and (max-width: 992px) {
  .nowater{
    width: 200px;
    margin: 0 auto;
  }
  .about-banner{
    width: 100%;
    height: 309.99px;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .about-banner{
    width: 100%;
    height: 232.31px;
    object-fit: cover;
  }
}
@media screen and (max-width: 600px) {
  .about-banner{
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
.state-input {
  width: 100%;
}
.error {
  color: red;
  font-size: 12px;
}
.icon-cont {
  position: relative;
  margin-bottom: 15px;
}
.iconxx {
  position: absolute;
  left: 20px;
  top: 55%;
  transform: translateY(-50%);
  color: #aaa;
}
.spass {
  padding-left: 50px;
}
.bordr {
  border: 1px solid #f9a526;
}
.contactperas {
  color: #8c8c8c;
  text-align: center;
  font-family: RalewayMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 600px) {
  .contactperas {
    font-size: 18px;
  }
}

img {
  pointer-events: none;
}
/* p{
  margin: 0;
} */
.no-copy-text {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}
@font-face {
  font-family: RalewayMedium;
  src: url(../Ralewayfont/Raleway-Medium.ttf);
}
@font-face {
  font-family: RalewayBold;
  src: url(../Ralewayfont/Raleway-Bold.ttf);
}
@font-face {
  font-family: RubikMedium;
  src: url(../Rubik/Rubik-Medium.ttf);
}
.product-banner {
  width: 100%;
  height: 568.9px;
  object-fit: fill;
}

/* new css add */
.card-flex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 50px;
  /* max-width: 389px;
  min-width: 280px; */
  /* height: 565px; */
  /* border: 1px solid red; */
  /* background-color: #111111; */
}
.card {
  /* max-width: 389px; 
  min-width: 250px;
  height: 565px; */
  width: 100%;
  padding: 10px;
  background-color: #111111;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); */
  /* margin-bottom: 20px; */
  border-radius: 15px;
  border: 1px solid #413118;
  text-align: center;
}
.card img {
  /* max-width: 349px;
  min-width: 280px; */
  height: 300px;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  object-fit: cover;
}
.blog-date {
  color: #ffad31;
  font-size: 16px;
  font-family: Releway-Bold;
  font-weight: 400;
  line-height: 18.78px;
  text-align: left;
  margin-left: 15px;
}
.from-heading {
  color: #ffad31;
  font-size: 18px;
  font-family: RalewayBold;
  font-weight: 700;
  line-height: 25px;
  text-align: justify;
}
.from-pera {
  color: #878787;
  font-size: 16px;
  font-family: Raleway;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
}

.but-try-it-blog button {
  color: #ffad31;
  background: none;
  font-family: RalewayBold;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 19px;
  border: none;
  padding: 5px 20px;
}

/* Blog new class */
.read-more-btn {
  align-items: center;
  gap: 8px;
}

.read-more-btn i {
  margin-left: 8px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1200px) {
  .card-flex {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .card-flex {
    grid-template-columns: 1fr;
  }
}

/* banner part */
@media (min-width: 320px) and (max-width: 480px) {
  .productpera {
    color: #aaa;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 15px;
  }
}
@media screen and (max-width: 1400px) {
  .product-banner {
    width: 100%;
    height: 491.23px;
    object-fit: fill;
  }
}
@media screen and (max-width: 1200px) {
  .product-banner {
    width: 100%;
    height: 413.55px;
    object-fit: fill;
  }
}
@media screen and (max-width: 992px) {
  .product-banner {
    width: 100%;
    height: 309.99px;
    object-fit: fill;
  }
}

@media screen and (max-width: 767px) {
  .product-banner {
    width: 100%;
    height: 232.31px;
    object-fit: fill;
  }
}
@media screen and (max-width: 600px) {
  .product-banner {
    width: 100%;
    height: 300px;
    object-fit: fill;
  }
}

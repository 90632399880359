@font-face {
  font-family: Raleway;
  src: url(../Ralewayfont/Raleway-Regular.ttf);
}

img {
  pointer-events: none;
}
.close-btns{
  margin-left: 95%;
}
.event-banner {
  width: 100%;
  height: 568.9px;
  object-fit: cover;
}

.no-copy-text {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.middle {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: nowrap; */
}
.eventtext {
  position: relative;
  text-align: center;
}
.imageevent {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  /* height: 70vh; */
  /* object-fit: cover; */
  /* cursor: pointer; */
}
.eventsssssss-img {
  /* border-radius: 10px; */
  /* border: 1px solid #FFAD31; */
  width: 400px;
  object-position: cover;
}
.readmore {
  /* position: absolute; */
  /* bottom: -40px;
  left: 74%; */
  border-radius: 6px;
  /* background: #ffad31; */
  /* padding: 10px; */
  /* color: #fff; */
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.titlemore {
  position: absolute;
  bottom: 40px;
  left: 16%;
  border-radius: 6px;

  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.placemore {
  position: absolute;
  /* bottom: -45px;
  left: 16%; */
  border-radius: 6px;
  color: #ffad31;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* border: 1px solid black;
  background: #ffad31;
  padding: 5px 15px; */
}
/* .images-models{
  width: 200px;
} */
.titletext {
  color: #ffad31;
  font-family: Raleway;
  font-weight: bolder;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.placetext {
  color: #ffad31;
  font-size: 20px;
  font-family: raleway;
  font-weight: bolder;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.eventdescription {
  font-family: raleway;
  font-size: 14px;
  text-align: center;
  color: #818181;
  padding: 0 30px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .readmore {
    /* position: absolute; */
    /* bottom: -35px;
    left: 67%; */
    border-radius: 6px;
    /* background: #ffad31; */
    /* padding: 5px; */
    /* color: #fff; */
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .titlemore {
    position: absolute;
    bottom: 40px;
    left: 16%;
    border-radius: 6px;

    color: #fff;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .placemore {
    position: absolute;
    /* bottom: -42px;
    left: 16%; */
    border-radius: 6px;

    color: #fff;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .readmore {
    /* position: absolute; */
    /* bottom: -34px;
    left: 59%; */
    border-radius: 6px;
    /* background: #ffad31;
    padding: 5px;
    color: #fff; */
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .titlemore {
    position: absolute;
    bottom: 40px;
    left: 16%;
    border-radius: 6px;

    color: #fff;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .placemore {
    position: absolute;
    /* bottom: -42px;
    left: 16%; */
    border-radius: 6px;

    color: #fff;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .close-btns{
    margin-left: 90%;
  }
  .readmore {
    /* position: absolute; */
    /* bottom: -35px;
    left: 55%; */
    border-radius: 6px;
    /* background: #ffad31;
    padding: 2px;
    color: #fff; */
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .titlemore {
    position: absolute;
    bottom: 40px;
    left: 16%;
    border-radius: 6px;

    color: #fff;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .placemore {
    position: absolute;
    /* bottom: -42px;
    left: 16%; */
    border-radius: 6px;

    color: #fff;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
@media screen and (max-width: 600px) {
  .imageevent {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .event-banner {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1000px) {
  .close-btns{
    margin-left: 92%;
  }
  .eventsssssss-img {
    /* border-radius: 10px;  */
    /* border: 1px solid #FFAD31; */
    width: 100%;
    object-position: cover;
  }
  .middle {
    /* display: contents; */
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 1400px) {
  .event-banner{
    width: 100%;
    height: 491.23px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1200px) {
  .event-banner{
    width: 100%;
    height: 413.55px;
    object-fit: cover;
  }
}
@media screen and (max-width: 992px) {
  
  .event-banner{
    width: 100%;
    height: 309.99px;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .event-banner{
    width: 100%;
    height: 232.31px;
    object-fit: cover;
  }
}
